import React from 'react'
import { Carousel, Col, Row } from 'antd';


const Videos = () => {
    
  return (
    <div>
    <Row gutter={20}>
    <Col span={8} xs={24} sm={24} lg={8}>
    <video width="400" controls>
  <source src="img/video1.mp4" type="video/mp4"/>
 
</video>
    </Col>
    <Col span={8} xs={24} sm={24} lg={8}>
    <video width="400" controls>
    <source src="img/video2.mp4" type="video/mp4"/>
   
  </video>
    </Col>
    </Row>
   
     
     
     
    
    </div>
  )
}

export default Videos