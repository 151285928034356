import React from 'react'
import { Carousel } from 'antd';

const Portfolio = () => {
    let filenames = [  "pic1.jpg", "pic2.jpg", "pic3.jpg", "pic4.jpg", "pic5.jpg", "pic6.jpg",  "pic7.jpg", "pic8.jpg", "pic9.jpg", "pic10.jpg", "pic11.jpg", "pic12.jpg",  "pic13.jpg", "pic14.jpg", "pic15.jpg", "pic16.jpg", "pic17.jpg", "pic18.jpg",  "pic19.jpg", "pic20.jpg", "pic21.jpg", "pic22.jpg", "pic23.jpg", "pic24.jpg",  "pic25.jpg", "pic26.jpg", "pic27.jpg", "pic28.jpg", "pic29.jpg", "pic30.jpg",  "pic31.jpg", "pic32.jpg", "pic33.jpg", "pic34.jpg", "pic35.jpg", "pic36.jpg",  "pic37.jpg", "pic38.jpg", "pic39.jpg", "pic40.jpg", "pic41.jpg", "pic42.jpg",  "pic43.jpg", "pic44.jpg", "pic45.jpg", "pic46.jpg", "pic47.jpg", "pic48.jpg"]
;

    
    const images = filenames.map((image) => {
        return (
          <img
            alt="imag"
            src={(`img/${image}`)}
          />
        );
      });
   
  return (
    <div>
    
    <div className="container-fluid bg-light py-6 px-5">
    <div className="text-center mx-auto mb-5" style={{maxWidth:"600px"}}>
        <h1 className="display-5 text-uppercase mb-4">Some Of Our <span className="text-primary">Popular</span> Dream Projects</h1>
    </div>
   
    <div class="row g-5 portfolio-container">
    <div class="col-xl-4 col-lg-6 col-md-6 portfolio-item" id="ace-ananta">
        <div class="position-relative portfolio-box">
            <img class="img-fluid w-100" src="img/ace1.jpg" alt="Ace Ananta"/>
            <a class="portfolio-title shadow-sm" href="#" onclick="showPortfolio('ace-ananta')">
                <p class="h4 text-uppercase">Ace Ananta</p>
                <span class="text-body">
                    <i class="fa fa-map-marker-alt text-primary me-2"></i> Tatti Annaram, Nagole, Hyderabad, Telangana 500068
                </span>
            </a>
            <div class="portfolio-content ace-ananta">
                <a class="portfolio-btn" href="img/ace2.jpg" data-lightbox="ace-ananta">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/ace3.jpg" data-lightbox="ace-ananta">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/ace4.jpg" data-lightbox="ace-ananta">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/ace5.jpg" data-lightbox="ace-ananta">
                    <i class="bi bi-plus"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-lg-6 col-md-6 portfolio-item" id="aakruthi-arcadia">
        <div class="position-relative portfolio-box">
            <img class="img-fluid w-100" src="img/aak3.jpg" alt="Aakruthi Arcadia"/>
            <a class="portfolio-title shadow-sm" href="#" onclick="showPortfolio('aakruthi-arcadia')">
                <p class="h4 text-uppercase">AAKRUTHI ARCADIA</p>
                <span class="text-body">
                    <i class="fa fa-map-marker-alt text-primary me-2"></i> Gajularamaram, Hyderabad, Telangana 500055
                </span>
            </a>
            <div class="portfolio-content aakruthi-arcadia">
                <a class="portfolio-btn" href="img/aak2.jpg" data-lightbox="aakruthi-arcadia">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/aak1.jpg" data-lightbox="aakruthi-arcadia">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/aak4.jpg" data-lightbox="aakruthi-arcadia">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/aak5.jpg" data-lightbox="aakruthi-arcadia">
                    <i class="bi bi-plus"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-lg-6 col-md-6 portfolio-item" id="affinity-brundaavana">
        <div class="position-relative portfolio-box">
            <img class="img-fluid w-100" src="img/affinitybrindavana1.jpg" alt="Affinity Brundaavana"/>
            <a class="portfolio-title shadow-sm" href="#" onclick="showPortfolio('affinity-brundaavana')">
                <p class="h4 text-uppercase">Affinity Brundaavana</p>
                <span class="text-body">
                    <i class="fa fa-map-marker-alt text-primary me-2"></i> Sampigehalli, Jakkur Main Road, Bengaluru – 560064
                </span>
            </a>
            <div class="portfolio-content affinity-brundaavana">
                <a class="portfolio-btn" href="img/affinitybrindavana1.jpg" data-lightbox="affinity-brundaavana">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/affinitybrindavana2.jpg" data-lightbox="affinity-brundaavana">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/affinitybrindavana3.jpg" data-lightbox="affinity-brundaavana">
                    <i class="bi bi-plus"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-lg-6 col-md-6 portfolio-item" id="saideep-hullas">
        <div class="position-relative portfolio-box">
            <img class="img-fluid w-100" src="img/saideep1.jpg" alt="Saideep Hullas"/>
            <a class="portfolio-title shadow-sm" href="#" onclick="showPortfolio('saideep-hullas')">
                <p class="h4 text-uppercase">Saideep Hullas</p>
                <span class="text-body">
                    <i class="fa fa-map-marker-alt text-primary me-2"></i> #33/2B, Old Madras Rd, near Budigere Cross, Bandapura, Karnataka 560049
                </span>
            </a>
            <div class="portfolio-content saideep-hullas">
                <a class="portfolio-btn" href="img/saideep2.jpg" data-lightbox="saideep-hullas">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/saideep3.jpg" data-lightbox="saideep-hullas">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/saideep4.jpg" data-lightbox="saideep-hullas">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/saideep1.jpg" data-lightbox="saideep-hullas">
                    <i class="bi bi-plus"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-lg-6 col-md-6 portfolio-item" id="acs-prime-villas">
        <div class="position-relative portfolio-box">
            <img class="img-fluid w-100" src="img/acs1.jpg" alt="ACS Prime Villas"/>
            <a class="portfolio-title shadow-sm" href="#" onclick="showPortfolio('acs-prime-villas')">
                <p class="h4 text-uppercase">ACS Prime Villas</p>
                <span class="text-body">
                    <i class="fa fa-map-marker-alt text-primary me-2"></i> Mokila, Hyderabad, Telangana 501503
                </span>
            </a>
            <div class="portfolio-content acs-prime-villas">
                <a class="portfolio-btn" href="img/acs1.jpg" data-lightbox="acs-prime-villas">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/acs2.jpg" data-lightbox="acs-prime-villas">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/acs3.jpg" data-lightbox="acs-prime-villas">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/acs4.jpg" data-lightbox="acs-prime-villas">
                    <i class="bi bi-plus"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-lg-6 col-md-6 portfolio-item" id="golkanda-hotel">
        <div class="position-relative portfolio-box">
            <img class="img-fluid w-100" src="img/golkanda.jpg" alt="Golkanda Hotel"/>
            <a class="portfolio-title shadow-sm" href="#" onclick="showPortfolio('golkanda-hotel')">
                <p class="h4 text-uppercase">Golkanda Hotel</p>
                <span class="text-body">
                    <i class="fa fa-map-marker-alt text-primary me-2"></i> Mehdipatnam - Banjara Hills Rd, Hyderabad, Telangana 500028
                </span>
            </a>
            <div class="portfolio-content golkanda-hotel">
                <a class="portfolio-btn" href="img/golkanda.jpg" data-lightbox="golkanda-hotel">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/golkanda2.jpg" data-lightbox="golkanda-hotel">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/golkanda3.jpg" data-lightbox="golkanda-hotel">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/golkanda4.jpg" data-lightbox="golkanda-hotel">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/golkanda5.jpg" data-lightbox="golkanda-hotel">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/golkanda6.webp" data-lightbox="golkanda-hotel">
                    <i class="bi bi-plus"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-lg-6 col-md-6 portfolio-item" id="svastha-hospital">
        <div class="position-relative portfolio-box">
            <img class="img-fluid w-100" src="img/svasthahospital1.JPG" alt="Svastha Hospital"/>
            <a class="portfolio-title shadow-sm" href="#" onclick="showPortfolio('svastha-hospital')">
                <p class="h4 text-uppercase">Svastha Hospital</p>
                <span class="text-body">
                    <i class="fa fa-map-marker-alt text-primary me-2"></i> Narayanappa Garden, Whitefield, Bengaluru, Karnataka 560066
                </span>
            </a>
            <div class="portfolio-content svastha-hospital">
                <a class="portfolio-btn" href="img/svasthahospital1.JPG" data-lightbox="svastha-hospital">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/svasthahospital2.JPG" data-lightbox="svastha-hospital">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/svasthahospital3.jpg" data-lightbox="svastha-hospital">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/svasthahospital4.jpg" data-lightbox="svastha-hospital">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/svasthahospital6.jpg" data-lightbox="svastha-hospital">
                    <i class="bi bi-plus"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-lg-6 col-md-6 portfolio-item" id="ongoing-projects">
        <div class="position-relative portfolio-box">
            <img class="img-fluid w-100" src="img/portfolio-6.jpg" alt="OnGoing Projects"/>
            <a class="portfolio-title shadow-sm" href="#" onclick="showPortfolio('ongoing-projects')">
                <p class="h4 text-uppercase">OnGoing Projects</p>
            </a>
            <div class="portfolio-content ongoing-projects">
                <a class="portfolio-btn" href="img/portfolio-6.jpg" data-lightbox="ongoing-projects">
                    <i class="bi bi-plus"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="col-xl-4 col-lg-6 col-md-6 portfolio-item" id="centering-material">
        <div class="position-relative portfolio-box">
            <img class="img-fluid w-100" src="img/material4.jpeg" alt="Centering Material For Rent"/>
            <a class="portfolio-title shadow-sm" href="#" onclick="showPortfolio('centering-material')">
                <p class="h4 text-uppercase">Centering Material For Rent</p>
            </a>
            <div class="portfolio-content centering-material">
                <a class="portfolio-btn" href="img/material1.jpg" data-lightbox="centering-material">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/material8.jpeg" data-lightbox="centering-material">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/material3.jpg" data-lightbox="centering-material">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/material4.jpeg" data-lightbox="centering-material">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/material5.jpg" data-lightbox="centering-material">
                    <i class="bi bi-plus"></i>
                </a>
                <a class="portfolio-btn" href="img/material7.jpg" data-lightbox="centering-material">
                    <i class="bi bi-plus"></i>
                </a>
            </div>
        </div>
    </div>
</div>


</div>
    </div>
  )
}

export default Portfolio