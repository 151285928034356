import React, { useRef } from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const Carousel1 = () => {
    const audioRef = useRef(null);

    const playAudio = () => {
      audioRef.current.play();
    };
    
  return (
    <div>
    <AudioPlayer
    autoPlay={true}
    src="img/audio.mp3"
    showJumpControls={false}
   
    // onPlay={e => console.log("onPlay")}
    // other props here
  />
    <div className="container-fluid p-0">
    <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
                <img className="w-100" src="img/sribalaji1.jpg" alt="Image"/>
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div  className="p-3" style={{maxWidth:"900px",marginTop:"40%"}}>
                        <h1  className="display-2 text-uppercase text-white mb-md-4">SRI BALAJI ECSTATIC INFRA LLP</h1>
                        
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src="img/carousel-2.jpg" alt="Image"/>
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{maxWidth:"900px"}}>
                        <h1 className="display-2 text-uppercase text-white mb-md-4">We Are Trusted For Your Project</h1>
                    </div>
                </div>
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
            data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
            data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div>
</div>
    </div>
  )
}

export default Carousel1