import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from "./components/Home"
import Header from "./components/Header"
import Carousel from "./components/Carousel"
import AboutUs from "./components/About"
import Services from "./components/Services"
import Portfolio from "./components/Portfolio"
import Team from "./components/Team"
import Testimonial from "./components/Testimonial"
import Footer from "./components/Footer"
import Videos from "./components/Videos"
import Message from "./components/Message"

function App() {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/message" element={<Message />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
