import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <div>
    <div className="container-fluid sticky-top bg-dark bg-light-radial shadow-sm px-5 pe-lg-0">
        <nav className="navbar navbar-expand-lg bg-dark bg-light-radial navbar-dark py-3 py-lg-0">
            <Link to="/" className="navbar-brand">
                <h1 className="m-0 display-4 text-uppercase text-white"><i className="bi bi-building text-primary me-2"></i>SBC</h1>
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-0">
                    <Link to="/" className="nav-item nav-link active">Home</Link>
                    <Link to="/about" className="nav-item nav-link">About</Link>
                    <Link to="/services" className="nav-item nav-link">Service</Link>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                        <div className="dropdown-menu m-0">
                            <Link to="/message" className="dropdown-item">Director Message</Link>
                            <Link to="/portfolio" className="dropdown-item">Our Project</Link>
                            <Link to="/testimonial" className="dropdown-item">Testimonial</Link>
                        </div>
                    </div>
                    <Link to="/footer" className="nav-item nav-link">Contact</Link>
                    <div  className="nav-item nav-link bg-primary text-white px-5 ms-3 d-none d-lg-block"> </div>
                </div>
            </div>
        </nav>
    </div>
    
    </div>
  )
}

export default Header