import React from 'react'

const About = () => {
  return (
    <div>
    <div className="container-fluid py-6 px-5">
    <div className="row g-5">
        <div className="col-lg-7">
            <h1 className="display-5 text-uppercase mb-4">We are <span className="text-primary">the Leader</span> in Construction Industry</h1>
            <h4 className="text-uppercase mb-3 text-body">Welcome to Sri Balaji Ecstatic Infra LLP, where innovation meets craftsmanship and excellence is our standard. Founded in 2007, we have established ourselves as a leading name in the construction industry, serving clients with integrity and dedication.</h4>
            <p>Our journey began with a vision to build more than just structures, our goal has always been to foster enduring relationships founded on trust and mutual respect. Over the years, we have steadfastly adhered to this vision, consistently delivering exceptional results and earning the trust of our clients and the admiration of our peers. Our commitment to these core values has been the cornerstone of our success, guiding us in every project and interaction.</p>
            <div className="row gx-5 py-2">
                <div className="col-sm-6 mb-2">
                    <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Perfect Planning</p>
                    <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Professional Workers</p>
                    <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>First Working Process</p>
                </div>
                <div className="col-sm-6 mb-2">
                    <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Quality Materials</p>
                    <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Commitment to Safety</p>
                    <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Environmental Responsibility</p>
                </div>
            </div>
           
        </div>
        <div className="col-lg-5 pb-5" style={{minHeight:"400px"}}>
            <div className="position-relative bg-dark-radial h-100 ms-5">
                <img className="position-absolute w-100 h-100 mt-5 ms-n5" src="img/building.jpg" style={{objectFit:"cover"}}/>
            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default About