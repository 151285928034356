import React from 'react'
import { Carousel } from 'antd';
const Testimonial = () => {
    const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};
  return (
    <div>
   
    <div className="container-fluid bg-light py-6 px-5">
    <div className="text-center mx-auto mb-5" style={{maxWidth:"600px"}}>
        <h1 className="display-5 text-uppercase mb-4">What Our <span className="text-primary">Happy Clients</span> Say!!!</h1>
    </div>
    <div className="row gx-0 align-items-center">
        <div className="col-xl-4 col-lg-5 d-none d-lg-block">
            <img className="img-fluid w-100 h-100" src="img/testimonial2.jpg"/>
        </div>
        <div className="col-xl-8 col-lg-7 col-md-12">
            <div className="testimonial bg-light">
            <Carousel autoplay>
            

                    <div  className="row gx-4 align-items-center">
                        <div className="col-xl-4 col-lg-5 col-md-5">
                            <img className="img-fluid w-100 h-100 bg-light p-lg-3 mb-4 mb-md-0" src="img/user1.png" alt=""/>
                        </div>
                        
                        <div className="col-xl-8 col-lg-7 col-md-7">
                            <h4 className="text-uppercase mb-0">- Prathik</h4>
                            <p className="fs-5 mb-0"><i className="fa fa-2x fa-quote-left text-primary me-2"></i>Sri Balaji Ecstatic Infra LLP exceeded my expectations with their quality craftsmanship and attention to detail. From start to finish, they were professional and transparent, making the entire process smooth and stress-free. Highly recommend them for any construction project!</p>
                        </div>
                    </div>
                    <div className="row gx-4 align-items-center">
                        <div className="col-xl-4 col-lg-5 col-md-5">
                            <img className="img-fluid w-100 h-100 bg-light p-lg-3 mb-4 mb-md-0" src="img/user1.png" alt=""/>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-md-7">
                            <h4 className="text-uppercase mb-0">- Ajay</h4>
                            <p className="fs-5 mb-0"><i className="fa fa-2x fa-quote-left text-primary me-2"></i> Impressed by the innovative designs and timely delivery from Sri Balaji Ecstatic Infra LLP. Their customer-centric approach ensured that my needs were met every step of the way. A trustworthy choice for anyone looking for a reliable construction partner.</p>
                        </div>
                    </div>
                    <div className="row gx-4 align-items-center">
                        <div className="col-xl-4 col-lg-5 col-md-5">
                            <img className="img-fluid w-100 h-100 bg-light p-lg-3 mb-4 mb-md-0" src="img/user1.png" alt=""/>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-md-7">
                            <h4 className="text-uppercase mb-0">- Harish</h4>
                            <p className="fs-5 mb-0"><i className="fa fa-2x fa-quote-left text-primary me-2"></i> Sri Balaji Ecstatic Infra LLP stands out for their exceptional workmanship and commitment to sustainability. They delivered a beautiful, eco-friendly space that exceeded my expectations. A top-notch construction company that I would gladly recommend.</p>
                        </div>
                    </div>
                    <div className="row gx-4 align-items-center">
                        <div className="col-xl-4 col-lg-5 col-md-5">
                            <img className="img-fluid w-100 h-100 bg-light p-lg-3 mb-4 mb-md-0" src="img/user1.png" alt=""/>
                        </div>
                        <div className="col-xl-8 col-lg-7 col-md-7">
                            <h4 className="text-uppercase mb-0">- Niranjan</h4>
                            <p className="fs-5 mb-0"><i className="fa fa-2x fa-quote-left text-primary me-2"></i> Choosing Sri Balaji Ecstatic Infra LLP was the best decision I made for my project. Their team's professionalism, attention to detail, and timely completion made the entire experience hassle-free. I couldn't be happier with the results!</p>
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default Testimonial