import React from 'react'
import Carousel from "./Carousel"
import About from './About'
import Videos from './Videos'
import Portfolio from './Portfolio'
import Testimonial from './Testimonial'
import Message from './Message'
import Services from './Services'


const Home = () => {
  return (
    <div>
    <Carousel/>
    <About/>
    <Message/>
    <Services/>
    <Videos/>
    <Portfolio/>
    <Testimonial/>
    </div>
  )
}

export default Home