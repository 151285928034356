import React from 'react'

const About = () => {
  return (
    <div>
    <body>
    <section class="director-message">
        <div class="container">
            <div class="image">
                <img src="img/director.jpg" alt="Director's Photo"/>
            </div>
            <div class="message">
                <h1>Message from the Director</h1>
                <h1>Welcome to Sri Balaji Ecstatic Infra LLP</h1>
                <p>I am <b>Y. Venkateswarlu Reddy,</b>  and it is with great pride and excitement that I extend a warm greeting to you on behalf of our entire team. At Sri Balaji Ecstatic Infra LLP, we are committed to excellence in the construction and infrastructure sector, driven by a passion for creating spaces that elevate the quality of life for our clients and communities.</p><br/>
                <p>We understand that construction is not just about building structures, but about building relationships and trust. Our team of experienced professionals works collaboratively to ensure that every project is delivered on time, within budget, and to the highest standards of craftsmanship.</p><br/>
                <p>Warm regards,,<br/><b>Y. Venkateswarlu Reddy</b><br/><b>Managing Director</b><br/><b>Sri Balaji Ecstatic Infra LLP</b></p>
            </div>
        </div>
    </section>
   
</body>
    </div>
    
  )
}

export default About