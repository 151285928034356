import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div>
    <div className="footer container-fluid position-relative bg-dark bg-light-radial text-white-50 py-6 px-5">
    <div className="row g-5">
        <div className="col-lg-6 pe-lg-5">
            <a href="index.html" className="navbar-brand">
                <h1 className="m-0 display-4 text-uppercase text-white"><i className="bi bi-building text-primary me-2"></i>SBC</h1>
            </a>
            <p></p>
            <p><i className="fa fa-map-marker-alt me-2">&nbsp;Bengaluru</i><br/>
            Site No 23, Flat No A1, First Floor Office, Valepura,Varthur,Bengaluru 560087</p>
            <p><i className="fa fa-map-marker-alt me-2">&nbsp;Hyderabad</i><br/>
            FLAT NO .302 ,AMPLIO BUILDING, DREAM HOME APARTMENT , RAVI ENCLAVE, OPP. APARNA TOWERS, Kondapur, Hyderabad, 500084.</p>
            <p><i className="fa fa-phone-alt me-2"></i>GSTIN : 29AEUFS1675R1ZO</p>
            <p><i className="fa fa-phone-alt me-2"></i>GSTIN : 36AEUFS1675R1ZT</p>
            <p><i className="fa fa-phone-alt me-2"></i>CONTACT :<a href="tel:+919980046222">+91 9980046222</a>,<a href="tel:+918068708475">08068708475</a></p>
            <p><i className="fa fa-envelope me-2"></i>EMAIL : <a href="mailto:yvreddy@sribalajillp.in">yvreddy@sribalajillp.in</a></p>
            <div className="d-flex justify-content-start mt-4">
                <a className="btn btn-lg btn-primary btn-lg-square rounded-0 me-2" href="#"><i className="fab fa-twitter"></i></a>
                <a className="btn btn-lg btn-primary btn-lg-square rounded-0 me-2" href="#"><i className="fab fa-facebook-f"></i></a>
                <a className="btn btn-lg btn-primary btn-lg-square rounded-0 me-2" href="#"><i className="fab fa-linkedin-in"></i></a>
                <a className="btn btn-lg btn-primary btn-lg-square rounded-0" href="#"><i className="fab fa-instagram"></i></a>
            </div>
        </div>
        <div className="col-lg-6 ps-lg-5">
            <div className="row g-5">
                <div className="col-sm-6">
                    <h4 className="text-white text-uppercase mb-4">Quick Links</h4>
                    <div className="d-flex flex-column justify-content-start">
                        <Link className="text-white-50 mb-2" to="/"><i className="fa fa-angle-right me-2"></i>Home</Link>
                        <Link className="text-white-50 mb-2" to="/about"><i className="fa fa-angle-right me-2"></i>About Us</Link>
                        <Link className="text-white-50 mb-2" to="/services"><i className="fa fa-angle-right me-2"></i>Our Services</Link>
                        <Link className="text-white-50 mb-2" to="/portfolio"><i className="fa fa-angle-right me-2"></i>Our Project</Link>
                        <Link className="text-white-50 mb-2" to="/testimonial"><i className="fa fa-angle-right me-2"></i>Testimonial</Link>
                    </div>
                </div>
               
               
            </div>
        </div>
    </div>
</div>
<div className="container-fluid bg-dark bg-light-radial text-white border-top border-primary px-0">
    <div className="d-flex flex-column flex-md-row justify-content-between">
        <div className="py-4 px-5 text-center text-md-start">
            <p className="mb-0">&copy; <a className="text-primary" href="#">SBC</a>. All Rights Reserved.</p>
        </div>
        <div className="py-4 px-5 bg-primary footer-shape position-relative text-center text-md-end">
            <p className="mb-0">Designed by <a className="text-dark" href="https://rctechbox.vercel.app">RCTECHBOX IT SERVICES</a></p>
        </div>
    </div>
</div>
    </div>
  )
}

export default Footer